
<app-header></app-header>

<div class="container">
    <div class="row">
        <div class="col-md-6">
            <img src="/assets/cobrel-logo-101x120-white.png" width="50"/>
        </div>
        <div class="col-md-6">
            <p class="h3 text-right auth">Inventory</p>
        </div>
    </div>
    <hr>
    <router-outlet></router-outlet>
</div>
