import { Component, OnInit } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterModule, RouterOutlet } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AuthService } from './services/auth.service';
import { HeaderComponent } from './components/header/header.component';
import { CommonModule } from '@angular/common';
import { environment } from '../../environments/environment';

@Component({
    standalone: true,
    imports: [
        DashboardComponent,
        HeaderComponent,
        CommonModule,
        RouterModule,
        RouterOutlet,
        RouterLink,
        RouterLinkActive,
    ],
    providers: [
        AuthService
    ],
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {

    constructor(
        private authService: AuthService,
    ) {
    }

    ngOnInit() {
        console.log('App running on:', environment.url);
    }
}
