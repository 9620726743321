import { inject } from "@angular/core";
import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { CookieService } from "ngx-cookie-service";

export const authInterceptor: HttpInterceptorFn = (
    req: HttpRequest<any>,
    next: HttpHandlerFn,
): Observable<HttpEvent<any>> => {
    const cookieService = inject(CookieService);
    const accessToken = cookieService.get('access_token');

    if (accessToken) {
        const cloned = req.clone({
            headers: req.headers.set("Authorization",
                "Bearer " + accessToken),
        });

        return next(cloned);
    } else {
        return next(req);
    }
}
