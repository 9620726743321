<nav class="navbar navbar-default navbar-static-top navbar-inverse">
    <div class="container">
        <ul class="nav navbar-nav">
            <li [routerLinkActive]="'active'">
                <a routerLink="/dashboard"><span class="glyphicon glyphicon-home"></span> Home</a>
            </li>
            <li [routerLinkActive]="'active'">
                <a routerLink="/inventory"><span class="glyphicon glyphicon-list-alt"></span> Inventory</a>
            </li>
        </ul>
        <ul class="nav navbar-nav navbar-right">
            @if (!userName) {
                <li class="navbar-right">
                    <a class="btn btn-link" (click)="clickSignIn()">Sign In&nbsp;<span class="glyphicon glyphicon-log-in"></span></a>
                </li>
            } @else {
              <li class="navbar-right">
                  <a class="auth btn btn-link" (click)="clickSignOut()">Sign Out&nbsp;<span class="glyphicon glyphicon-log-out"></span></a>
              </li>
              <li class="navbar-right">
                  <a class="auth btn btn-link">
                      <span class="glyphicon glyphicon-user"></span>&nbsp;
                      <span id="name">{{ userName }}</span>
                  </a>
              </li>
            }
        </ul>
    </div>
</nav>
