import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { BehaviorSubject, map, Observable, Subject, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { UserDto } from '../../../../../libs/data/src/lib/dto/user.dto';

@Injectable()
export class AuthService {

    private apiUrl = environment.url + environment.api;
    private user: BehaviorSubject<UserDto|null> = new BehaviorSubject<UserDto|null>(null);

    constructor(
        @Inject(DOCUMENT) private document: Document,
        protected cookieService: CookieService,
        private http: HttpClient,
    ) {
        this.getCurrent$().subscribe({
            next: (user) => {
                this.user.next(user);
            },
            error: (httpErrorResponse: HttpErrorResponse) => {
                if (httpErrorResponse.error.status === HttpStatusCode.Unauthorized)
                this.user.next(null);
            }
        });
    }

    public signIn() {
        this.document.location.href = this.apiUrl + '/auth/microsoft/login';
    }

    public signOut() {
        this.cookieService.delete('access_token');
        this.user.next(null);
        this.document.location.href = '/'
    }

    public getUser$(): Subject<UserDto|null> {
        return this.user;
    }

    public isAuthorised$(): Observable<boolean> {
        return this.user.pipe(map((user) => !!user));
    }

    public getCurrent$(): Observable<UserDto> {
        return this.http.get<UserDto>(this.apiUrl + '/auth/current').pipe(
            tap((user => this.user.next(user))),
        );
    }
}
