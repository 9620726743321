import { Route } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { InventoryComponent } from './components/inventory/inventory.component';

export const appRoutes: Route[] = [
    {
        path: 'inventory',
        component: InventoryComponent,
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
    },
];
