import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiService } from '../../services/api.service';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';

@Component({
    selector: 'app-inventory',
    standalone: true,
    imports: [
        CommonModule,
        DataTablesModule
    ],
    providers: [
        ApiService
    ],
    templateUrl: './inventory.component.html',
    styleUrl: './inventory.component.scss',
})
export class InventoryComponent implements OnInit {
    @ViewChild(DataTableDirective, {static: false})
    datatableElement!: DataTableDirective;

    dtOptions = {};

    constructor(
        private apiService: ApiService,
    ) {
    }

    ngOnInit() {
        this.dtOptions = {
            ajax:  (dataTablesParameters: any, callback: any) => {
                this.apiService.getItems().subscribe(items => {
                    callback({
                        data: items
                    });
                })
            },
            columns: [{
                title: 'Code',
                data: 'Code'
            }, {
                title: 'Name',
                data: 'Name'
            }, {
                title: 'Description',
                data: 'Description'
            }, {
                title: 'Quantity',
                data: 'QuantityOnHand'
            }]
        };
    }
}
