import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { BehaviorSubject, map, Observable, Subject, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { UserDto } from '../../../../../libs/data/src/lib/dto/user.dto';

@Injectable()
export class ApiService {

    private apiUrl = environment.url + environment.api;
    private user: BehaviorSubject<UserDto|null> = new BehaviorSubject<UserDto|null>(null);

    constructor(
        @Inject(DOCUMENT) private document: Document,
        protected cookieService: CookieService,
        private http: HttpClient,
    ) {

    }

    public getItems(): Observable<any> {
        return this.http.get<any>(this.apiUrl + '/auth/xero/items').pipe(
            map(items => items.Items)
        );
    }
}
