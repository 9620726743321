import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { RouterLink, RouterLinkActive, RouterModule, RouterOutlet } from '@angular/router';

@Component({
    selector: 'app-header',
    standalone: true,
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss',
    imports: [
        RouterModule,
        RouterOutlet,
        RouterLink,
        RouterLinkActive,
    ]
})
export class HeaderComponent implements OnInit {

    public userName: string = '';

    constructor(
        private authService: AuthService,
    ) {
    }

    ngOnInit() {
        this.authService.getUser$().subscribe(
            (user) => {
                if( !!user) {
                    this.userName = user.firstName + ' ' + user.lastName;
                }
            },
        );
    }

    public clickSignIn() {
        this.authService.signIn();
    }

    public clickSignOut() {
        this.authService.signOut();
    }
}
