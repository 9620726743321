
<div class="row">
    <div class="col-md-12">
        <table datatable [dtOptions]="dtOptions" class="row-border hover"></table>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <br/>
    </div>
</div>

<div class="row">
    <div class="col-md-12 ">
        <div id="message" class="alert alert-info text-center" role="alert">
            Loading, please wait...
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12 ">
        <div class="text-right">
            <span id="lastUpdated"></span>
        </div>
    </div>
</div>
